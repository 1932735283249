import { CCol, CFormInput, CInputGroup, CInputGroupText, CRow } from "@coreui/react-pro";
import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { DeleteIcon, DragIcon } from "../../assets/images";
import { useDrag } from 'react-dnd'
import DragableInput from "./DragableInput";
import { initialPortTab, IPortSec, IPortTab } from "../../slice/dispatcherSlice";
import update from 'immutability-helper'
import _ from 'lodash';
import { Form } from "react-bootstrap";


export interface EditTab {
  id: string,
  name: string,
  secs: IPortSec[];
  required: boolean
}

interface IDragContainerProps {
  editingTabs: IPortTab[];
  dispatchReducer: React.Dispatch<any>;
  showAddNew?: boolean;
  onInitiateDelete?: (index: number, name: string) => void;
}


const DragContainer = forwardRef(({
    editingTabs,
    dispatchReducer,
    showAddNew = true,
    onInitiateDelete
}: IDragContainerProps, _ref) => {

  const [tabs, setTabs] = useState<EditTab[]>(editingTabs);

  useImperativeHandle(_ref, () => ({
    getTabsState: () => {
      return tabs
    }
  }));

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    let testFunction = (prevCards: EditTab[]) =>
        update(prevCards, {
        $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as EditTab],
        ],
    })
    setTabs(testFunction);

  }, [])


  //Add tab to local state
  const addNewTab = () => {
    let newTabs = [...tabs];
    newTabs.push({
      ...initialPortTab,
      id:""});
    setTabs(newTabs);
  }

  const onBlurTabName = (target, index) => {
    let newTabs = [...tabs];
    newTabs[index].name = target;
    setTabs(newTabs);
  }

  const removeTab = (index, editTabs) => {
    const tempEditTabs = [...editTabs];
    tempEditTabs.splice(index, 1);
    setTabs(tempEditTabs);
  }

  const initiateTabDelete = useCallback((index, name) => {
    if (onInitiateDelete) {
      onInitiateDelete(index, tabs[index]?.name || "");
    } else {
      removeTab(index, tabs);
    }
  }, [tabs, onInitiateDelete]);

  return (
    <>
      {tabs.map((tab, index) => 
          <DragableInput
            key={`Tab${index}`}
            value={tab?.name}
            isDeletable={!tab?.required}
            index={index}
            moveCard={moveCard}
            onBlur={onBlurTabName}
            removeTab={initiateTabDelete}
            dispatchReducer={dispatchReducer}
            editTabs={tabs}
          />
      )}
       {(showAddNew === undefined || showAddNew === true) && (
          <Form.Label className="editTab" onClick={() => addNewTab()}>+ Add new tab</Form.Label>
        )}
    </>
  );
});

export default memo(DragContainer);

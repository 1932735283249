const SEC_TITLES = {
    runwayName: 'Runway Name',
    notes: 'Notes',
    lvoApproach: 'LVO Approach',
    approach: 'Approach',
    takeOff: 'Takeoff',
    engineFailure: 'Engine Failure',
    takeOff_Lights:'Takeoff Lights',
    takeOff_Performance:'Takeoff Performance'
};

const SECS_DISPLAYITEMS= [SEC_TITLES.lvoApproach, SEC_TITLES.approach, SEC_TITLES.takeOff];
const SECS_DISPLAYTEXT=[SEC_TITLES.runwayName, SEC_TITLES.notes, SEC_TITLES.engineFailure];

const PDF_LAYOUT_MODE = {
    WHITE: 'WHITE',
    DARK: 'DARK'
}
const ERROR_MESSAGE = {
    API_RUNWAY_SAVE_ERROR: 'The data not saved due to technical error',
    SERVER_ERROR: 'An unexpected error occurred',
    DUPLICATE_RUNWAY_NAMES: "Runway names must be unique.",
    DUPLICATE_RUNWAY_NAME: (name) => `Runway name "${name}" already exists.`
}

const CONFIRM_MESSAGES = {
    DELETE_RUNWAY_TAB: (tabName) => `Are you sure you want to delete ${tabName || 'this'} Runway tab? All Information on the tab will be removed.`,
    DELETE_RUNWAY_TAB_TITLE: (tabName) => `Delete ${tabName || 'this'} Runway Tab`,
};
export {
    SEC_TITLES,
    SECS_DISPLAYITEMS,
    SECS_DISPLAYTEXT,
    PDF_LAYOUT_MODE,
    ERROR_MESSAGE,
    CONFIRM_MESSAGES
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../app/store";
import { closeFlightObject, closeFlightSearchObject } from "../interfaces/closeFlight";
import { flightPlanLengthObject, flightPlanObject } from "../interfaces/flightPlan";
import { flightStatusObject } from '../interfaces/flightStatus';

interface DispatcherSlice {
    flightPlan:         Array<flightPlanObject>;
    flightPlanLength:   flightPlanLengthObject;
    flightStatus:       Array<flightStatusObject>;
    closeFlightSearch:  any;
    closeFlightList:    Array<closeFlightObject>;
    closeFlightDetail:  any;
    flightDetail:       any;
    snnRule:            Array<ISnnRuleObject>;
    fuelAdvConfigs:      Array<IFuelAdvisoryConfigObject>;
    defect:             Array<IDefectObject>;
    cxDefects:          Array<IDefectObject>;
    nsuNotam:           Array<INsuNotamObject>;
    cxNsuNotam:         Array<INsuNotamObject>;
    oeNotam:            Array<IOeNotamObject>;
    cxOeNotam:          Array<IOeNotamObject>;
    appendixRule:       Array<IAppendixRuleObject>;
}

interface FlightPlanPayload {
    flightPlan:         flightPlanObject[];
    flightPlanLength:   flightPlanLengthObject;
}

interface FlightStatusPayload {
    flightStatus:       Array<flightStatusObject>;
}

interface CloseFlightListPayload {
    closeFlightList:    Array<closeFlightObject>;
}

interface CloseFlightDetailPayload {
    closeFlightDetail:    object;
}

interface FlightDetailPayload {
    flightDetail:    object;
}

interface ISnnRuleObject {
    airline:          string;
    flightNo:         string;
    aircraftType:     string;
    dep:              string;
    arr:              string;
    altn:             string;
    era:              string;
    registration:     string;
    snn:              string;
    waypoint:         string;
    countryICAOCode:  string;
    startAt:          Date;
    endAt:            Date;
    timeRange:        string;
    createAt:         Date;
}

interface SnnRulePayload {
    snnRules:           Array<ISnnRuleObject>;
}

interface IFuelAdvisoryConfigObject {
    icao:         string,
    emails:       string[],
    active:       boolean,
    lastUpdated:  Date,
    galacxyId:    string,
}

interface FuelAdvisoryConfigPayload {
    fuelAdvConfigs:     Array<IFuelAdvisoryConfigObject>;
}

const initialPort = {
    tabs: []
}

const initialPortTab = {
    name: "",
    secs: [],
    required: false
}

const initialPortTabContent: IPortSec = {
    id: "",
    name: "",
    items: [{id: '', type: 'MD', value:['']}],
    required: false
}

const initialPortReducer = {
    portName: "",
    airportName: "",
    cityName: "",
    name: "",
    countryName: "",
    iata: "",
    icao: "",
    originalTabs: [],
    tabs: []
  }
interface IPortPayload {
    galacxyId: string;
    iata: string;
    icao: string;
    airline: string;
    name: string;
    cityName: string;
    countryName: string;
    createdBy: string;
}

interface IPort {
    portName: string;
    icao: string;
    iata: string;
    name: string;
    cityName: string;
    countryName: string;
    createdAt: string;
    createdBy: string;
    version: number;
    tabs: IPortTab[]
}

interface IPortTab {
    id: string;
    name: string;
    secs: IPortSec[];
    // order: number;
    required: boolean;
}

interface IPortSec {
    id?: string;
    name: string;
    items: IPortSecItem[];
    // order: number;
    required: boolean;
}

interface IPortSecItem {
    id: string;
    prefix?: string;
    suffix?: string;
    type: string;
    value: string[];
    files?: IPortAttachment[];
    pattern?: string;
    warning?: string;
}

interface IPortAttachment {
    fileId: string;
    name: string;
    filePath: string;
}

interface IAttachment {
    id: string;
    name: string;
    imageUrl: string;
    fileType: string;
}

interface ISavePortElementRequest {
    galacxyId: string;
    airline: string;
    icao: string;
    type: string;
    tabs: IPortTab[];
    createdBy: string;
}

interface IReOrderTabRequest {
    galacxyId: string;
    airline: string;
    icao: string;
    tabIds: string[];
}

interface IUploadPortFilesRequest {
    galacxyId: string;
    airline: string;
    icao: string;
    files: FormData[];
}

interface IDownloadPortFileRequest {
    galacxyId: string;
    airline: string;
    icao: string;
    fileId: string;
}

interface IDeletePortFileRequest {
    galacxyId: string;
    airline: string;
    icao: string;
    itemId: string;
    fileId: string;
}

interface IDefectObject {
    aircraft:         string;
    addNumber:        string;
    addTitle:         string;
    addDetail:        string;
    melReference:     string;
    deferType:        string;
    displayDeferType: string;
    created:          Date;
    createdAt:        Date;
    deletedAt?:       Date; //UO use
    deleteAt?:        Date; //CX use
    expiryDate:       Date;
    __v:              number;
    _id:              string;
}

interface INsuNotamObject {
    category:     string;
    icao:         string;
    airline:      string;
    flightNo:     string;
    dep:          string;
    arr:          string;
    startAt:      Date;
    endAt:        Date;
    review:       Date;
    initiator:    string;
    text:         string;
    remark:       string;
    status:       string;
    created:      Date;
    __v:          number;
}

interface IOeNotamObject {
    acType:       string;
    acSubType:    string;
    text:         string;
    startz:       Date;
    endz:         Date;
    iata:         string;
    icao:         string;
    __v:          number;
    created?:      Date;
}

interface IAppendixRuleObject {
    airline:        string;
    flightNo:       string;
    dep:            string;
    arr:            string;
    altn:           string;
    era:            string;
    aircraftType:   string;
    registration:   string;
    expiry:         Date;
    review:         string;
    files:          Array<string>;
    createAt:       Date;
    updateAt:       Date;
}

interface DefectPayload {
    defects:           Array<IDefectObject>;
}

interface NsuNotamPayload {
    nsuNotam:           Array<INsuNotamObject>;
}

interface OeNotamPayload {
    oeNotam:           Array<IOeNotamObject>;
}

interface AppendixRulePayload {
    appendixRule:           Array<IAppendixRuleObject>;
}

interface AlertObject {
    send: string;
    receive: string[];
    alertAt: string;
}

const initialNewPortForm: IPortPayload = {
    galacxyId: "",
    iata: "",
    icao: "",
    airline: "CPA",
    name: "",
    cityName: "",
    countryName: "",
    createdBy: ""
}

const flightPlanLengthInitialState: flightPlanLengthObject = {
    AOSAlertFlightPlansLength: 0,
    changedFlightPlansLength: 0,
    draftMZFWFlightPlansLength: 0,
    latestErrorFlightPlansLength: 0,
    missingAdjFlightPlansLength: 0,
    stopShowingFlightPlansLength: 0,
};

const closeFlightSearchInitialState: closeFlightSearchObject= {
    stdStart: '',
    stdEnd: '',
    uoStdStart: '',
    uoStdEnd: '',
}

const initialState: DispatcherSlice = {
  flightPlan:         [],
  flightPlanLength:   flightPlanLengthInitialState,
  flightStatus:       [],
  closeFlightSearch:  closeFlightSearchInitialState,
  closeFlightList:    [],
  closeFlightDetail:  {},
  flightDetail:       {},
  snnRule:            [],
  fuelAdvConfigs:     [],
  defect:             [],
  cxDefects:          [],
  nsuNotam:           [],
  cxNsuNotam:         [],
  oeNotam:            [],
  cxOeNotam:          [],
  appendixRule:       [],
};

const dispatcherSlice = createSlice({
    name: "dispatch",
    initialState,
    reducers: {
        setFlightPlans: (state, { payload }: PayloadAction<FlightPlanPayload>) => {
            state.flightPlan = payload.flightPlan;
            state.flightPlanLength = payload.flightPlanLength;
        },
        setFlightstatus: (state, { payload }: PayloadAction<FlightStatusPayload>) => {
            state.flightStatus = payload.flightStatus;
        },
        setCloseFlightSearch: (state, { payload }: PayloadAction<closeFlightSearchObject>) => {
            state.closeFlightSearch = payload;
        },
        setCloseFlightList: (state, { payload }: PayloadAction<CloseFlightListPayload>) => {
            state.closeFlightList = payload.closeFlightList;
        },
        setCloseFlightDetail: (state, { payload }: PayloadAction<CloseFlightDetailPayload>) => {
            state.closeFlightDetail = payload;
        },
        setFlightDetail: (state, { payload }: PayloadAction<FlightDetailPayload>) => {
            state.flightDetail = payload;
        },
        setSnnRules: (state, { payload }: PayloadAction<SnnRulePayload>) => {
            state.snnRule = payload.snnRules;
        },
        setFuelAdvisoryConfig: (state, { payload }: PayloadAction<FuelAdvisoryConfigPayload>) => {
            state.fuelAdvConfigs = payload.fuelAdvConfigs;
        },
        setDefects: (state, { payload }: PayloadAction<DefectPayload>) => {
            state.defect = payload.defects;
        },
        setCXDefects: (state, { payload }: PayloadAction<DefectPayload>) => {
            state.cxDefects = payload.defects;
        },
        setNsuNotam: (state, { payload }: PayloadAction<NsuNotamPayload>) => {
            state.nsuNotam = payload.nsuNotam;
        },
        setCXNsuNotam: (state, { payload }: PayloadAction<NsuNotamPayload>) => {
            state.cxNsuNotam = payload.nsuNotam;
        },
        setOeNotam: (state, { payload }: PayloadAction<OeNotamPayload>) => {
            state.oeNotam = payload.oeNotam;
        },
        setCXOeNotam: (state, { payload }: PayloadAction<OeNotamPayload>) => {
            state.cxOeNotam = payload.oeNotam;
        },
        setAppendixRule: (state, { payload }: PayloadAction<AppendixRulePayload>) => {
            state.appendixRule = payload.appendixRule;
        },
        resetFlightPlans: (state) => {
            state.flightPlan = [];
            state.flightPlanLength = flightPlanLengthInitialState;
        },
        resetFlightStatus: (state) => {
            state.flightStatus = [];
        },
        resetSnnRules: (state) => {
            state.snnRule = [];
        },
        resetFuelAdvisoryConfig: (state) => {
            state.fuelAdvConfigs = [];
        },
        resetDefects: (state) => {
            state.defect = [];
        },
        resetCXDefects: (state) => {
            state.cxDefects = [];
        },
        resetNsuNotam: (state) => {
          state.nsuNotam = [];
        },
        resetCXNsuNotam: (state) => {
          state.cxNsuNotam = [];
        },
        resetOeNotam: (state) => {
          state.oeNotam = [];
        },
        resetCXOeNotam: (state) => {
          state.cxOeNotam = [];
        },
        resetAppendixRule: (state) => {
          state.appendixRule = [];
      },
    }
});
const initialRunwayReducer = {
    airline: "",
    icao: "",
    tabs: []
  };

const selectDispatcher = (state: RootState) => state.dispatch;
const {
    setFlightPlans,
    setFlightstatus,
    setCloseFlightSearch,
    setCloseFlightList,
    setCloseFlightDetail,
    setFlightDetail,
    setSnnRules,
    setFuelAdvisoryConfig,
    setDefects,
    setCXDefects,
    setNsuNotam,
    setCXNsuNotam,
    setOeNotam,
    setCXOeNotam,
    setAppendixRule,
    resetFlightPlans,
    resetFlightStatus,
    resetSnnRules,
    resetFuelAdvisoryConfig,
    resetDefects,
    resetCXDefects,
    resetNsuNotam,
    resetCXNsuNotam,
    resetOeNotam,
    resetCXOeNotam,
    resetAppendixRule
} = dispatcherSlice.actions;

export {
    dispatcherSlice,
    selectDispatcher,
    setFlightPlans,
    setFlightstatus,
    setCloseFlightSearch,
    setCloseFlightList,
    setCloseFlightDetail,
    setFlightDetail,
    setSnnRules,
    setFuelAdvisoryConfig,
    setDefects,
    setCXDefects,
    setNsuNotam,
    setCXNsuNotam,
    setOeNotam,
    setCXOeNotam,
    setAppendixRule,
    resetFlightPlans,
    resetFlightStatus,
    resetSnnRules,
    resetFuelAdvisoryConfig,
    resetDefects,
    resetNsuNotam,
    resetCXNsuNotam,
    resetCXDefects,
    resetOeNotam,
    resetCXOeNotam,
    resetAppendixRule,
    initialNewPortForm,
    initialPort,
    initialPortTab,
    initialPortTabContent,
    initialPortReducer,
    initialRunwayReducer
};
export type { DispatcherSlice, ISnnRuleObject, IFuelAdvisoryConfigObject, IDefectObject, IPortPayload, IPortTab, IPortSec, IPortSecItem, IAttachment, ISavePortElementRequest, IReOrderTabRequest, IUploadPortFilesRequest, IDownloadPortFileRequest, IDeletePortFileRequest, AlertObject };

export default dispatcherSlice.reducer;

import React, { useState, useEffect, useCallback, useRef, useReducer } from 'react';
import { CRow, CCol } from '@coreui/react-pro';
import { Nav, Tab, Modal, Form } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import moment from 'moment';

import { CXButton, CXDropDown } from '../../components/CXForm';
import PortPageRunwayContentContainer from './PortPageRunwayContentContainer'
import { addNewRunway, resetRunways, duplicateRunway, selectPortPageRunway } from '../../slice/portPageRunwaySlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import portRunwayService, { IContentSecsShowObj } from '../../services/portPageRunway/portRunwayAPI';
import ReminderModal from '../../components/ReminderModal';
import { PDF_LAYOUT_MODE, ERROR_MESSAGE, CONFIRM_MESSAGES } from '../../constants/portPageRunwayConstant';
import { generatePdf } from '../../components/PDFGen/PDFGenBase';
import _ from 'lodash';
import Access from '../../components/Access';
import { AccessType } from '../../constants/roleAccessConstant';
import { useLocation } from "react-router-dom";
import DragContainer from '../../components/FlightPlanConfig/DragContainer';
import { selectUser } from '../../slice/userSlice';
import { initialRunwayReducer } from '../../slice/dispatcherSlice';
import { pushToast } from '../../slice/appSlice';

interface PortPageRunwayTabContainerProps {
    isEditMode: boolean,
    setIsEditMode: Function,
    runways: Array<any>,
    calltoGetRunways: Function,
    portDetail: {
        airline: string;
        icao: string;
        cityName: string;
        countryName: string;
    };
}


const PortPageRunwayTabContainer = (props: PortPageRunwayTabContainerProps) => {
    const { pathname: routePath } = useLocation();
    const dispatch = useAppDispatch();
    const { isEditMode, setIsEditMode, runways, calltoGetRunways, portDetail } = props;
    const [tabKey, setTabKey] = useState<number>(0);
    const [isAddNewRunway, setIsAddNewRunway] = useState<boolean>(false);
    const [isDupRunway, setIsDupRunway] = useState<boolean>(false);
    const [isSaveReminder, setIsSaveReminder] = useState<boolean>(false);
    const [pdfLayoutMode, setPDFLayoutMode] = useState<string>(PDF_LAYOUT_MODE.WHITE);
    const [isExportPDF, setIsExportPDF] = useState<boolean>(false);
    const { tempRunwayNames } = useAppSelector(selectPortPageRunway);
    const pdfLayoutModeOptions = [
        {
            text: PDF_LAYOUT_MODE.WHITE,
            value: PDF_LAYOUT_MODE.WHITE
        },
        {
            text: PDF_LAYOUT_MODE.DARK,
            value: PDF_LAYOUT_MODE.DARK
        }
    ]

    const initRunwayWhenFirstLandOn = () => {
        // new port don't have runway at the very beginning, need to create default one base on template
        const cleanObj = portRunwayService.getDefaultNewRunwayObject(portDetail);
        dispatch(addNewRunway({ cleanObj }));
    }

    const onAddNewRunway = useCallback((tabIndex = 0) => {
        let safeTabIndex = tabIndex;
        if(safeTabIndex < 0 || safeTabIndex >= runways.length){
            safeTabIndex = 0;
        }
        const cleanObj = portRunwayService.getCleanRunwayObj({ ...runways[safeTabIndex]});//some unexpected errors come out when call in redux(portPageRunwaySlice)
        dispatch(addNewRunway({ cleanObj }));
        setTabKey(runways ? runways.length : tabKey);
        setIsAddNewRunway(true);
    }, [dispatch, portDetail, runways, tabKey])

    const onDuplicate = () => {
        dispatch(duplicateRunway({ tabIndex: tabKey }));
        setTabKey(runways ? runways.length : tabKey);
        setIsDupRunway(true);
    }

    const onCancelEdit = () => {
        setIsSaveReminder(true);
    }
    const resetRunwayStateFlags = useCallback(() => {
        setIsAddNewRunway(false);
        setIsDupRunway(false);
    }, []);

    const validateRunwayName = (
        runway: any = null, 
        allTabs: Array<{name?: string, runway?: string}> | null = null, 
        currentTabIndex: number | null = null
    ) => {
        // Case 1: Validate a specific runway against existing runways (for single runway save)
        if (runway && currentTabIndex !== null) {
            const runwayName = tempRunwayNames[currentTabIndex];
            if (!runwayName) {
                return false; 
            }
            const isDuplicate = runways.some((existingRunway, index) => 
                index !== currentTabIndex &&
                existingRunway.runway &&
                existingRunway.runway.toLowerCase() === runwayName.toLowerCase()
            );
            
            if (isDuplicate) {
                dispatch(pushToast({ 
                    type: "error", 
                    message: ERROR_MESSAGE.DUPLICATE_RUNWAY_NAME(runwayName)
                }));
                return true;
            }
            return false; 
        }
        
        // Case 2: Validate all tabs for duplicate names (for tabs modal save)
        if (allTabs) {
            const names = allTabs.map(tab => tab?.name || tab?.runway);
            const uniqueNames = new Set(names);
            
            if (names.length !== uniqueNames.size) {
                const duplicates = names.filter((name, index) => 
                    names.indexOf(name) !== index
                );
                
                dispatch(pushToast({ 
                    type: "error", 
                    message: duplicates.length > 0
                        ? ERROR_MESSAGE.DUPLICATE_RUNWAY_NAME(duplicates[0])
                        : ERROR_MESSAGE.DUPLICATE_RUNWAY_NAMES
                }));
                return true;
            }
            return false;
        }
        return false; 
    };

    const onConfirmSave = useCallback((runwaySave, tabKey) => {
        if (validateRunwayName(runwaySave, null, tabKey)) {
            return;
        }
        const submitRunway = _.clone(runwaySave);
        if (submitRunway) {
            submitRunway.runway = tempRunwayNames[tabKey];
            submitRunway.lvoApproach = submitRunway.lvoApproach || [];
            submitRunway.approach = submitRunway.approach || [];
            submitRunway.uuid = submitRunway.uuid || '';

            portRunwayService.postRunwaySave(submitRunway).then(res => {
                if (res?.status === 500  || res?.status === 400 || res?.status === 404) {
                    // setResultFound(false);
                    dispatch(pushToast({ 
                        type: "error", 
                        message: ERROR_MESSAGE.API_RUNWAY_SAVE_ERROR
                    }));
                    setTabKey(0);
                    resetRunwayStateFlags();
                }else {
                    calltoGetRunways();
                    setIsEditMode(false);
                    setIsSaveReminder(false);
                    resetRunwayStateFlags();
                }
            }).catch(() => {
                dispatch(pushToast({ 
                    type: "error", 
                    message: ERROR_MESSAGE.SERVER_ERROR
                }));
                resetRunwayStateFlags();
            });
        }
    }, [calltoGetRunways, setIsEditMode, tempRunwayNames, validateRunwayName, resetRunwayStateFlags])

    const onHideSaveReminderModal = () => {
        setIsSaveReminder(false);
    }

    const onCancelClick = () => {
        setIsSaveReminder(false);
    }

    const onConfirmCancelClick = () => {
        dispatch(resetRunways({ tabIndex: tabKey }));
        setIsEditMode(false);
        setIsSaveReminder(false);
        setIsAddNewRunway(false);
        setIsDupRunway(false);
        setTabKey(0);
    }

    const onCancelExportPDF = () => {
        setIsExportPDF(false);
    }

    const onConfirmExportPDF = () => {
        handleExportPDF();
        setIsExportPDF(false);
    }

    const handleExportPDF = () => {
        const currentRunway = runways[tabKey];
        const contentSecsShowObj: IContentSecsShowObj = {
            notes: true,
            lvoApproach: false, // remove / hidden at all time, ref task 2072
            approach: true,
            takeOff: true,
            engineFailure: true
        };

        const content = portRunwayService.getPDFContentArray({ ...currentRunway, ...portDetail }, contentSecsShowObj);
        const outputPDFRunwayObject = {
            content,
            footer: ''
        };

        // 10-7A-<PORT ICAO>-<runway>.pdf, according story #FCDOP3-3382
        const fileName = `10-7A-${portDetail.icao}-${currentRunway.runway}`;

        generatePdf(outputPDFRunwayObject).download(fileName);
    }

    useEffect(() => {
        if (runways && runways.length === 0) {
            initRunwayWhenFirstLandOn();
            setTabKey(tabKey);
        }
    }, [runways])

    const reminderModalProps = {
        isShow: isSaveReminder,
        onHide: onHideSaveReminderModal,
        onCancelClick,
        onConfirmCancelClick
    };
    
    
    /* ******************************* FOR DELETION ******************************  */

    interface TabToDeleteType {
        index: number;
        name: string;
        tabsState: any[];
    }

    const [isEditRunwayTabModal, setIsEditRunwayTabModal] = useState<boolean>(false);
    const runwayTabsRef = useRef<any>();
    const { userId } = useAppSelector(selectUser);
    
    const runwayReducer = (state, action) => {
        switch (action.type) {
          case "LOAD":
            return {
              ...state,
              airline: action.runway.airline,
              icao: action.runway.icao,
              tabs: action.runway.tabs || [],
            };
          default:
            return state;
        }
      };
    
    const [runwayModal, dispatchRunwayReducer] = useReducer(runwayReducer, {
        ...initialRunwayReducer,
        tabs: []
    });    

    const closeModal = () => {
        setTabKey(0);
        setIsEditRunwayTabModal(false);
        setIsSaveReminder(false);
      }

      const openEditTabsModal = () => {
        const mappedTabs = runways
        .filter(runway=> runway.uuid && runway.runway)
        .map((runway, index) => ({
          id: runway.id || '',
          name: runway.runway,
          required: index === 0,
          uuid: runway.uuid || '',          
        }));
        
        dispatchRunwayReducer({ 
          type: "LOAD", 
          runway: {
            airline: portDetail.airline,
            icao: portDetail.icao,
            tabs: mappedTabs
          }
        });

        setIsEditRunwayTabModal(true);
    };


    const onSaveRunwayTabs = () => {
        
        if (runwayTabsRef && runwayTabsRef.current) {
          const tabsState = runwayTabsRef.current.getTabsState();
        
        if (validateRunwayName(null, tabsState)) {
            return;
        }

          let tempTabs = [...tabsState];
          
          const tempRunway = {
            airline: portDetail.airline,
            icao: portDetail.icao,
            type: "tab",
            galacxyId: userId,
            tabs: tempTabs,
            createdBy: userId
        };

        portRunwayService.postSaveRunwayTab(tempRunway).then((res) => {
            if(res.status === 400){
                dispatch(pushToast({ 
                    type: "error", 
                    message: ERROR_MESSAGE.API_RUNWAY_SAVE_ERROR
                }));
                return;
            }
            dispatchRunwayReducer({ type: "LOAD", runway: res });
            calltoGetRunways();
            closeModal();
            setIsEditMode(false);
          }).catch(() => {
            dispatch(pushToast({ 
                type: "error", 
                message: ERROR_MESSAGE.SERVER_ERROR
            }));
          });
        }
        
        setIsEditRunwayTabModal(false);
      };

        /******************************* Deletion Confirmation Modal ***************************/
        
        const [isDeleteConfirmModalVisible, setIsDeleteConfirmModalVisible] = useState<boolean>(false);
        const [tabToDelete, setTabToDelete] = useState<TabToDeleteType | null>(null);

        const onConfirmDeleteTab = useCallback(()=>{
            try {
                if (tabToDelete !== null && tabToDelete.tabsState && Array.isArray(tabToDelete.tabsState)) {
                    const tabBeingDeleted = tabToDelete.tabsState[tabToDelete.index];            
                    const tempRunway = {
                        airline: portDetail.airline,
                        icao: portDetail.icao,
                        type: "tab",
                        galacxyId: userId,
                        tabs: tabBeingDeleted,
                        createdBy: userId
                    };
                    
                    portRunwayService.postDeleteRunwayTab(tempRunway).then((res) => {
                        if(res.status === 400){
                            // setIsEditRunwayTabModal(false);
                            dispatch(pushToast({ type: "error", message: ERROR_MESSAGE.API_RUNWAY_SAVE_ERROR }));
                            closeModal();
                            return;
                        }
                        const updatedTabsForUI = tabToDelete.tabsState.filter((_, i) => i !== tabToDelete.index);
                        dispatchRunwayReducer({ 
                                type: "LOAD", 
                                runway: {
                                airline: portDetail.airline,
                                icao: portDetail.icao,
                                tabs: updatedTabsForUI
                            }
                        });
                        calltoGetRunways();
                        setIsDeleteConfirmModalVisible(false);
                        setTabToDelete(null);
                        setIsEditMode(false);
                        dispatch(pushToast({
                            type: "success", 
                            message: `Tab "${tabToDelete.name}" was deleted successfully at ${moment().format('HH:mm:ss')}`
                        }));
                        setIsEditRunwayTabModal(true);
                        setTabKey(0);
                }).catch((err) => {
                        dispatch(pushToast({ type: "error", message: ERROR_MESSAGE.SERVER_ERROR }));
                        setIsDeleteConfirmModalVisible(false);
                        setTabToDelete(null);
                });
                }
            }catch(err){
                dispatch(pushToast({ 
                    type: "error", 
                    message: ERROR_MESSAGE.SERVER_ERROR
                }));
                setIsDeleteConfirmModalVisible(false);
                setTabToDelete(null);
            }
        }, [tabToDelete, portDetail, userId, calltoGetRunways, dispatch]);

        // Function for canceling deletion
        const onCancelDeleteTab = useCallback(() => {
            setIsDeleteConfirmModalVisible(false);
            setTabToDelete(null);
        }, []);

        // Function to show the delete confirmation modal
        const onInitiateDeleteTab = useCallback((index: number, name: string) => {
            const currentTabsState = runwayTabsRef.current?.getTabsState();
            setTabToDelete({ 
                index, 
                name,
                tabsState: currentTabsState
            });
            setIsEditRunwayTabModal(false);
            setIsDeleteConfirmModalVisible(true);
        }, []);
            
    /******************************* Dletetion Confirmation Modal END***************************/
    /* ******************************* FOR DELETION END ******************************  */
    const renderContent = () => {
        return (
            <>
                <Tab.Container id="left-tabs-example" activeKey={tabKey} onSelect={(key) => setTabKey(key ? parseInt(key) : 0)}>
                    <CRow className='tabRow ps-0'>
                        <CCol sm={8} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Nav variant="pills" className={"p-2 ps-0"}>
                                {runways && runways.map((tab, index) =>
                                    <Nav.Item key={`TabName_${tab.runway}_${index}`}>
                                        <Nav.Link className={`${tabKey === index ? "selectedTab nav-link-fix-selected" : "tabButton nav-link-fix"}`} eventKey={index}>{tab.runway}</Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav>
                            {isEditMode && (
                                <Form.Label className="editTab" onClick={openEditTabsModal}>Edit tabs</Form.Label>
                            )}
                        </CCol>                    
                        <Access route={routePath} access={[AccessType.UPDATE]}>
                            {!isEditMode &&
                                <CCol sm={2} className={isEditMode ? "port-button" : ""}>
                                    <CXButton outline={true} text={"Edit"} onClick={() => { 
                                        setIsAddNewRunway(false);
                                        setIsDupRunway(false); 
                                        setIsEditMode(true)}
                                    } 
                                    disabled={isEditMode} fontSize={"16px"} width={"100%"} />
                                </CCol>
                            }
                        </Access>
                        {!isEditMode &&
                            <CCol sm={2} className={isEditMode ? "port-button" : ""}>
                                <CXButton text={"Export as PDF"} onClick={() => { handleExportPDF(); }} fontSize={"16px"} width={"100%"} disabled={isEditMode} />
                            </CCol>
                        }
                        <Access route={routePath} access={[AccessType.UPDATE]}>
                            {isEditMode &&
                                <>
                                    <CCol sm={2}></CCol>
                                    <CCol sm={2} className={(isAddNewRunway || isDupRunway) ? "port-button" : ""}>
                                        <CXButton outline={true} text={"Add New Runway"} onClick={() => { onAddNewRunway(tabKey) }} disabled={isAddNewRunway || isDupRunway} fontSize={"16px"} width={"100%"} />
                                    </CCol>
                                </>
                            }
                        </Access>
                    </CRow>
                    <CRow>
                        {runways && runways.map((tab, index) =>
                            <Tab.Content key={`TabContent_${tab.runway}_${index}`}>
                                <Tab.Pane eventKey={index}>
                                    <DndProvider backend={HTML5Backend}>
                                        <PortPageRunwayContentContainer
                                            runwayTabData={tab}
                                            isEditMode={isEditMode}
                                            tabIndex={index}
                                        />
                                    </DndProvider>
                                </Tab.Pane>
                            </Tab.Content>
                        )
                        }
                        <Access route={routePath} access={[AccessType.UPDATE]}>
                            {isEditMode &&
                                <CRow>
                                    <CCol sm={2} className={isDupRunway ? "port-button" : ""}>
                                        {!isAddNewRunway &&
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ bottom: "40px", right: "256px", width: "200px" }}><CXButton outline={true} text={"Duplicate"} onClick={() => onDuplicate()} fontSize={"16px"} width={"100%"} disabled={isDupRunway} /></div>
                                            </div>
                                        }
                                    </CCol>
                                    <CCol sm={6}></CCol>
                                    <CCol sm={4}>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <div style={{ bottom: "40px", right: "256px", width: "200px" }}><CXButton outline={true} text={"Cancel"} onClick={() => onCancelEdit()} fontSize={"16px"} width={"100%"} /></div>
                                            <div style={{ bottom: "40px", right: "40px", width: "200px", marginLeft: "15px" }}><CXButton text={"Save"} onClick={() => onConfirmSave(runways[tabKey], tabKey)} fontSize={"16px"} width={"100%"} /></div>
                                        </div>
                                    </CCol>
                                </CRow>
                            }
                        </Access>
                    </CRow>
                </Tab.Container>

                 {/* Edit tabs */}
                <Modal className="runwayModal" show={isEditRunwayTabModal} onHide={closeModal} size="lg">
                    <Modal.Header className="modal-header" closeButton>
                        <Modal.Title>Edit Tabs for</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DndProvider backend={HTML5Backend}>
                        <DragContainer
                            ref={runwayTabsRef}
                            editingTabs={runwayModal.tabs}
                            dispatchReducer={dispatchRunwayReducer} 
                            showAddNew={false}
                            onInitiateDelete={onInitiateDeleteTab}
                        />
                        </DndProvider>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <div style={{ bottom: "40px", right: "256px", width: "200px" }}><CXButton outline={true} text={"Cancel"} onClick={() => setIsEditRunwayTabModal(false)} fontSize={"16px"} width={"100%"} /></div>
                        <div style={{ bottom: "40px", right: "40px", width: "200px" }}><CXButton text={"Save"} onClick={() => onSaveRunwayTabs()} fontSize={"16px"} width={"100%"} /></div>
                    </Modal.Footer>
                </Modal>
                {isEditMode &&
                    <ReminderModal {...reminderModalProps} />
                }
                {!isEditMode &&
                    <Modal show={isExportPDF} onHide={() => setIsExportPDF(false)}>
                        <Modal.Header className="modal-header" closeButton>
                            <Modal.Title>Please choose the PDF layout mode you want </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CRow>
                                <CCol>
                                    <CXDropDown
                                        value={pdfLayoutMode}
                                        placeholder={"layout mode"}
                                        onChange={setPDFLayoutMode}
                                        options={pdfLayoutModeOptions}
                                        zIndex={1000} />
                                </CCol>
                            </CRow>
                        </Modal.Body>
                        <Modal.Footer className="modal-footer">
                            <div className='cancel-button'><CXButton outline={true} text={"Cancel"} onClick={() => onCancelExportPDF()} fontSize={"16px"} width={"100%"} /></div>
                            <div className='save-button'><CXButton text={"Confirm"} onClick={() => onConfirmExportPDF()} fontSize={"16px"} width={"100%"} /></div>
                        </Modal.Footer>
                    </Modal>
                }

                <Modal 
                className="runwayModal" 
                show={isDeleteConfirmModalVisible} 
                onHide={onCancelDeleteTab} 
                size="lg"
                >
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title>{CONFIRM_MESSAGES.DELETE_RUNWAY_TAB_TITLE(tabToDelete?.name)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>{CONFIRM_MESSAGES.DELETE_RUNWAY_TAB(tabToDelete?.name)}</p>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <div style={{ bottom: "40px", right: "256px", width: "200px" }}>
                    <CXButton 
                        outline={true} 
                        text={"Cancel"} 
                        onClick={onCancelDeleteTab} 
                        fontSize={"16px"} 
                        width={"100%"} 
                    />
                    </div>
                    <div style={{ bottom: "40px", right: "40px", width: "200px" }}>
                    <CXButton 
                        text={"Delete"} 
                        onClick={()=>onConfirmDeleteTab()} 
                        fontSize={"16px"} 
                        width={"100%"} 
                        // color={"danger"}
                    />
                    </div>
                </Modal.Footer>
                </Modal>
            </>
        )
    }

    return runways && runways?.length > 0
        ? renderContent()
        : null;
};

export default PortPageRunwayTabContainer;
